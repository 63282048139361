import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
// import adhocData from './mock_data/reorder_acne_studios_adhoc.json';
// import filterData from './mock_data/reorder_acne_studios_filter.json';
// import theoreticalData from './mock_data/reorder_acne_studios_theoretical.json';

import adhocData from './mock_data/reorder_diptyqueparis_adhoc.json';
import filterData from './mock_data/reorder_diptyqueparis_filter.json';
import theoreticalData from './mock_data/reorder_diptyqueparis_theoretical.json';

const mock = new AxiosMockAdapter(axios, { delayResponse: 1200 });

// Import

export function postImportData(jwtToken, useremail, ImportData, filename) {
  return axios.post(
    '/Import',
    { data: ImportData, useremail, filename },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

// get filters

mock.onGet('/filters').reply(200, filterData);

export function getFilters() {
  return axios.get('/filters', {});
}

mock.onGet('/notifications').reply(200, {
  month: 'October 2021',
  status: 1,
  scope: 1,
  dataAsOf: '20th September 2021'
});

export function getNotification() {
  return axios.get('/notifications');
}

mock
  .onGet('/validationstatus')
  .reply(200, { open: { type: 'Buffer', data: [1] } });

export function getValidationStatus() {
  return axios.get('/validationstatus');
}

mock.onPost('/uploads').reply(200, adhocData);

export function getUploads() {
  return axios.post('/uploads');
}

mock.onGet('/orders').reply(200, theoreticalData);

export function getOrders() {
  return axios.get('/orders', {});
}

mock.onPost('/edit').reply(200);

export function saveEdits() {
  return axios.post('/edit');
}

mock.onPost('/submit').reply(200);

export function postSubmit() {
  return axios.post('/submit');
}

mock.onPost('/unsubmit').reply(200);

export function postUnSubmit() {
  return axios.post('/unsubmit');
}

mock.onPost('/deleteuploads').reply(200);

export function deleteUpload(jwtToken, uploadid) {
  return axios.post('/deleteuploads');
}

mock.onPost('/email/reorderemail').reply(200);

export function reorderEmail() {
  return axios.post('/email/reorderemail');
}

// ----------- to be minimiced!

// get submission

export default {
  postImportData,
  getFilters,
  getUploads,
  deleteUpload,
  getOrders,
  saveEdits,
  postSubmit,
  postUnSubmit,
  getValidationStatus,
  getNotification,
  reorderEmail
};
