/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:e48f1bd7-8575-4371-8457-979e01fe39c3',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_h6TpVeuh8',
  aws_user_pools_web_client_id: '1q1nif9c58hgfslq3f4ghb7vpr',
  oauth: {}
};

export default awsmobile;
