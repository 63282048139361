// material
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMixPanel } from 'react-mixpanel-provider-component';

Selections.propTypes = {
  valueMetric: PropTypes.string,
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func
};

export default function Selections({
  valueMetric,
  currentFilters,
  setCurrentFilters
}) {
  const { mixpanel } = useMixPanel();

  const removedSelection = (filter) => {
    setCurrentFilters((prevData) => {
      const newData = { ...prevData };
      delete newData[filter];
      return newData;
    });
  };

  return (
    <>
      {Object.keys(currentFilters).map((filter) => (
        <Chip
          key={filter}
          label={currentFilters[filter].join(', ')}
          onClick={() => {
            removedSelection(filter);
          }}
          onDelete={() => {
            removedSelection(filter);
          }}
        />
      ))}

      <Chip key={valueMetric} label={valueMetric} />
    </>
  );
}
